import React, {useState} from "react";
import theme from '../gatsby-theme-material-ui-top-layout/theme';
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/layout";
import AccentFrame from "../components/accentFrame";
import { Section } from "../components/section";
import {Trans, useTranslation } from "gatsby-plugin-react-i18next";
import tbitgpni from "../images/TBITGPIN.svg";
import tbitgpni_en from "../images/TBITGPIN_en.svg"
import tbicn from "../images/TBICN.svg";
import tbicn_en from "../images/TBICN_en.svg";
import tbi from "../images/TBI.svg"
import tbi_en from "../images/TBI_en.svg"
import Li from "../components/li";
import Definition from "../components/definition";
import { graphql } from 'gatsby'
import PieChart from "../components/pieChart";
import { Box,
         FormControl,
         FormControlLabel,
         Radio,
         RadioGroup,
         Switch,
         Typography } from "@material-ui/core";
import Legend from "../components/legend";

const useStyles = makeStyles(() => ({
  imgTbiTgpni: {
    width: "100%",
    height: "85vh",
    objectFit: "contain"
  },
  imgTbiCn: {
    width: "100%",
    height: "35vh",
    objectFit: "contain",
  },
  imgTbi: {
    width: "100%",
    height: "35vh",
    objectFit: "contain",
  },
  low: {
    color: "#8f4a9e"
  },
  high: {
    color: "#2e2e7a"
  },
  present: {
    color:"#00b2c9"
  },
  notPresent: {
    color:"#0069ac"
  }
}))


function TbiInfo(props) {
  const {t} = useTranslation();
  const {percent} = props

  return (
    <div>
      <Typography>
        <strong>{t("Results after the integrated biochemical test")}</strong>
      </Typography>
      <ul>
        <Li>
          {!percent ? (
            <Trans i18nKey="screeningStat1">
             Out of 100,000 pregnant women who underwent an <Definition word="tbi">integrated biochemical test</Definition>, 
             3,102 women present a high probability of having a child with trisomy 21 and 96,898 women, 
             a low probability of having a child with trisomy 21.
            </Trans>

          ) : (
            <Trans i18nKey="screeningStatPercent1">
              Out of 100,000 pregnant women who underwent an <Definition word="tbi">integrated biochemical test</Definition>, 
              3.10% women present a high probability of having a child with trisomy 21 and 96.90% women, 
              a low probability of having a child with trisomy 21.
            </Trans>
          )}
        </Li>
        <Li>
          {t("The diagnostic test shows that:")}
          <ul>
            <Li>
              {percent? (
                <Trans i18nKey="screeningStatPercent2">
                  of the 86.71% women with a low probability result, 86.69% women are actually carrying a fetus without trisomy 21. 
                  Therefore, the <Definition word="tbi">integrated biochemical test</Definition> failed to 
                  detect 0.02% fetuses carrying trisomy 21.
                </Trans>
              ) : (
                <Trans i18nKey="screeningStat2">
                  of the 86 706 women with a low probability result, 86 691 women are actually carrying a fetus without trisomy 21. 
                  Therefore, the <Definition word="tbi">integrated biochemical test</Definition> failed to 
                  detect 15 fetuses carrying trisomy 21.
                </Trans>
              )}
 
            </Li>
            <Li>
              {!percent ? (
                t("among the 3,102 women with a high probability result, 106 women are actually carrying a fetus with trisomy 21.")
              ) : (
                t("among the 3.10% women with a high probability result, 0.11% women are actually carrying a fetus with trisomy 21.")
              )}
            </Li>
          </ul>
        </Li>
        <Li>
          <strong>
            {!percent ? (
              <Trans i18nKey="screeningStat3">
                In total, the <Definition word="tbi">integrated biochemical test</Definition> makes it possible to detect 106 of the 125 cases of trisomy 21.
              </Trans>

            ) : (
              <Trans i18nKey="screeningStatPercent3">
                In total, the <Definition word="tbi">integrated biochemical test</Definition> makes it possible to detect 0.11% of the 0.12% cases of trisomy 21.
              </Trans>
            )}
          </strong>
        </Li>
      </ul>
    </div>
  )
}

function TgpniInfo(props) {
  const {percent} = props
  const {t} = useTranslation();

  return (
    <div>
      <Typography>
        <strong>
          {t("Results after non-invasive prenatal testing results when the non-invasive prenatal testing is performed following the integrated biochemical test results.")}
        </strong>
      </Typography>
      <ul>
        <Li>
          {!percent ? (
            <Trans i18nKey="screeningStat4">
              The <Definition word="tgpni">non-invasive prenatal testing</Definition> indicates that 2,967 women present a low probability of having a child with trisomy 21 and 135 women, a high probability of having a child with trisomy 21.
            </Trans>
          ) : (
            <Trans i18nKey="screeningStatPercent4">
              The <Definition word="tgpni">non-invasive prenatal testing</Definition> indicates that 2.97% women present a low probability of having a child with trisomy 21 and 0.14% women, a high probability of having a child with trisomy 21.
            </Trans>
          )}
          <br/>
        </Li>
        <Li>{t("The diagnostic test shows that:")}</Li>
        <ul>
          <Li>
            {!percent ? (
              <Trans i18nKey="screeningStat5">
                among the 2,967 women with a low probability result, 2,966 women are carrying a fetus without trisomy 21. 
                Therefore, the <Definition word="tgpni">non-invasive prenatal testing</Definition> failed to detect 1 fetus with trisomy 21.
              </Trans>
            ) : (
              <Trans i18nKey="screeningStatPercent5">
                among the 2.97% women with a low probability result, 2.96% women are carrying a fetus without trisomy 21. 
                Therefore, the <Definition word="tgpni">non-invasive prenatal testing</Definition> failed to detect 1 fetus with trisomy 21.
              </Trans>
            )}
          </Li>
          <Li>
            {!percent ?(
              t("among the 135 women with a high probability result, 105 women are carrying a fetus with trisomy 21 and 30 women are carrying a fetus without trisomy 21.")
            ):(
              t("among the 0.14% women with a high probability result, 0.10% women are carrying a fetus with trisomy 21 and 0.03% women are carrying a fetus without trisomy 21.")
            )}
          </Li>
        </ul>
        <Li>
          <strong>
            {!percent ?(
              <Trans i18nKey="screeningStat6">
                In total, the <Definition word="tgpni">non-invasive prenatal testing</Definition>, 
                when performed following the <Definition word="tbi">integrated biochemical test</Definition> results,
                reduces the number of diagnostic tests from 3,102 to 135, and it detects 105 cases out of the 106 
                detected by <Definition word="tbi">the integrated biochemical test</Definition>."
              </Trans>
            ) : (
              <Trans i18nKey="screeningStatPercent6">
                In total, the <Definition word="tgpni">non-invasive prenatal testing</Definition>, 
                when performed following the <Definition word="tbi">integrated biochemical test</Definition> results,
                reduces the number of diagnostic tests from 3.10% to 0.24%, and it detects 0.10% cases out of the 0.13% 
                detected by <Definition word="tbi">the integrated biochemical test</Definition>."
              </Trans>
            )}
          </strong>
        </Li>
      </ul>
    </div>
  )
}

function TbiCnInfo(props) {
  const {percent} = props;
  const {t} = useTranslation();
  return(
    <div>
      <Typography component="span">
        <strong>
          {t("Results after the integrated biochemical test + nuchal translucency ultrasound screening test")}
        </strong>
      </Typography>
      <ul>
        <Li>
          {!percent ? (
            <Trans i18nKey="screeningStat7">
              Of 100,000 pregnant women who underwent <Definition word="tbi">integrated biochemical 
              test</Definition> + <Definition word="cn">nuchal translucency ultrasound screening</Definition>, the 
              results indicate that 4,515 women present a high probability of having a child  with trisomy and 95 485 women, a 
              low probability of having a child with trisomy.
            </Trans>
          ) : (
            <Trans i18nKey="screeningStatPercent7">
              Of 100 000 pregnant women who underwent <Definition word="tbi">integrated biochemical 
              test</Definition> + <Definition word="cn">nuchal translucency ultrasound screening</Definition>, the 
              results indicate that 4.52% women present a high probability of having a child  with trisomy and 95.59% women, a 
              low probability of having a child with trisomy.
            </Trans>
          )}
        </Li>
        <Li>{t("The diagnostic test shows that:")}</Li>
        <div>
          <ul>
              <Li>
                {!percent ? (
                  <Trans i18nKey="screeningStat8">
                    of the 95 485 women with a low probability result, 95 480 women have a fetus without 
                    trisomy 21. Therefore, the <Definition word="tbi">integrated biochemical test</Definition> + <Definition word="cn">nuchal 
                    translucency ultrasound</Definition> failed to detect 5 fetuses with trisomy 21.
                  </Trans>
                ) : (
                  <Trans i18nKey="screeningStatPercent8">
                    of the 95.49% women with a low probability result, 95.48% women have a fetus without 
                    trisomy 21. Therefore, the <Definition word="tbi">integrated biochemical test</Definition> + <Definition word="cn">nuchal 
                    translucency ultrasound</Definition> failed to detect 0.01% of fetuses with trisomy 21.
                  </Trans>
                ) }
              </Li>
              <Li>
                {!percent ?(
                  <Trans i18nKey="screeningStat9">
                    among the 4515 women with a high probability according to the <Definition word="tbi">integrated biochemical test</Definition> + <Definition word="cn">nuchal translucency ultrasound test</Definition> result, 120 women are actually carrying a fetus with trisomy 21.
                  </Trans>

                ) : (
                  <Trans i18nKey="screeningStatPercent9">
                    among the 4,52% women with a high probability according to the <Definition word="tbi">integrated biochemical test</Definition> + <Definition word="cn">nuchal translucency ultrasound test</Definition> result, 0.13% women are actually carrying a fetus with trisomy 21.
                  </Trans>
                )}
              </Li>
            </ul>
        </div>
        <Li>
          <strong>
            {!percent ? (
              <Trans i18nKey="screeningStat10">
                In total, the <Definition word="tbi">integrated biochemical test</Definition> + <Definition word="cn">nuchal 
                translucency ultrasound</Definition> makes it possible to detect 113 of the 125 cases of trisomy 21.
              </Trans>

            ) : (
              <Trans i18nKey="screeningStatPercent10">
                In total, the <Definition word="tbi">integrated biochemical test</Definition> + <Definition word="cn">nuchal 
                translucency ultrasound</Definition> makes it possible to detect 0.11% of the 0.12% cases of trisomy 21.
              </Trans>
            )}
          </strong>
        </Li>
      </ul>
    </div>

  )
}

function PieChartSection(props) {
  const {selected, inPercentage} = props;
  const {t} = useTranslation();
  
  const legendData = {
    low:{
      color:"#F7DBD7",
      label:t("High probability")
    },
    high:{
      color:"#779ecb",
      label:t("Low probability")
    },
    negative:{
      color:"#E1B78D",
      label:t("No case of trisomy")
    },
    positive:{
      color:"#b19cd9",
      label:t("Cases of trisomy")
    }
  }

  const total = {
    "tbi" : [{y: 86706}, {y: 13294}],
    "tbicn" : [{y: 95485}, {y: 4515}],
    "tbitpgni" : [{y: 13052}, {y: 242}],
  }

  const low = {
    "tbi" : [{y: 86691}, {y: 15 }],
    "tbicn" : [{y: 95480}, {y: 5 }],
    "tbitpgni" : [{y: 13036}, {y: 16}]
  }

  const high = {
    "tbi" : [{y: 13184}, {y: 110}],
    "tbicn" : [{y: 4395}, {y: 120}],
    "tbitpgni" : [{y: 133}, {y: 109}],
  }

  const centerLabels = {
    "tbi" : {
      "high" : "13 294",
      "low" : "86 706",
      "total" : "100 000",
    },
    "tbicn" : {
      "high" : "4515",
      "low" : "95 485",
      "total" : "100 000",
    },
    "tbitpgni" : {
      "high" : "242",
      "low" : "13 052",
      "total" : "13 294",
    }
  }

  return(
    <Box marginTop={theme.spacing(0.5)}>
      <Typography>
        {t("The top circle shows a population of 100,000 pregnant women being screened.")}
      </Typography>
      <Box width="100%" 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        marginBottom="-10%"
        padding="0 28% 0 28%">
          <PieChart data={total[selected]}
                    title="Total"
                    percent={inPercentage}
                    total={100000}
                    colorScale={["#779ecb", "#F7DBD7"]} 
                    centerLabel={centerLabels[selected].total}>
          </PieChart>
      </Box>
      <Box width="100%" 
            display="flex" 
            flexDirection="row" 
            alignItems="center" 
            marginBottom="30px">
        <PieChart data={low[selected]} 
                  colorScale={["#E1B78D", "#b19cd9"]} 
                  centerLabel={centerLabels[selected].low}
                  percent={inPercentage}
                  total={100000}
                  title={t("Low probability")}
                  circle={<circle cx={200} cy={200} r={50} 
                  fill="#779ecb"/>}>
        </PieChart>
        <PieChart data={high[selected]} 
                  colorScale={["#E1B78D", "#b19cd9"]}
                  title={t("High probability")}
                  percent={inPercentage}
                  total={100000}
                  centerLabel={centerLabels[selected].high} 
                  circle={<circle cx={200} cy={200} r={50} 
                  fill="#F7DBD7"/>}>
        </PieChart>
      </Box>
      <Legend elements={legendData}/>
    </Box>
  )
}

function ImageSection (props) {
  const {selected} = props;
  const classes = useStyles();
  const { i18n } = useTranslation();

  if (i18n.language === "fr") {
    if (selected === "tbi") {
      return <img src={tbi} alt="tbi" className={classes.imgTbi}/>
    } else if (selected === "tbicn") {
      return <img src={tbicn} alt="tbicn" className={classes.imgTbiCn}/>
    } else {
      return <img src={tbitgpni} alt="tbitpgni" className={classes.imgTbiTgpni}/>
    }
  } else {
    if (selected === "tbi") {
      return <img src={tbi_en} alt="tbi" className={classes.imgTbi}/>
    } else if (selected === "tbicn") {
      return <img src={tbicn_en} alt="tbicn" className={classes.imgTbiCn}/>
    } else {
      return <img src={tbitgpni_en} alt="tbitpgni" className={classes.imgTbiTgpni}/>
    }
  }
}

export default function Ressources() {
  const {t} = useTranslation();
  const columns = [t("Tests"), t("Integrated biochemical test"), t("Nuchal translucency ultrasound"), t("Non-invasive prenatal testing (NIPT)")];
  const [selected, setSelected] = useState("tbi");
  const [inImage, setInImage] = useState(false);
  const [inPercentage, setInPercentage] = useState(false);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };

  const handleToggleInImage = () => {
    setInImage(!inImage)
  }

  const handlePercentageToggle = () => {
    setInPercentage(!inPercentage);
  }

  const rows = [
    [
      t("Definition"), 
      t("Consists of blood test"),
      t("Consists of measuring the thickness of the fetal neck (space between the skin and the spine) by ultrasound."),
      t("Consists of one blood test during pregnancy to analyze free fetal DNA fragments from the placenta circulating in the maternal blood.")
    ],
  ];

  return (
    <Layout pageTitle={t("Find out more")}>
      <Section title={t("Important sites")}>
        <Typography>
          {t("If you are interested in learning more about the subject")}
        </Typography>
        <ul>
          <Li>
            <a href="https://www.msss.gouv.qc.ca/professionnels/perinatalite/programme-quebecois-de-depistage-prenatal/#a-propos">
              {t('resource1')}
            </a>
          </Li>
          <Li>
            <a href="https://www.ophq.gouv.qc.ca/">
            Office des personnes handicapées du Québec (OPHQ)
            </a>
          </Li>
          <Li>
            <a href="https://www.sqdi.ca/fr/">
              {t('resource2')}
            </a>
          </Li>
          <Li>
            <a href="http://www.cdss.ca/fr/">
              Canadian Down Syndrome Society (CDSS)
            </a>
          </Li>
          <Li>
            <a href="https://www.merckmanuals.com/fr-ca/accueil/probl%C3%A8mes-de-sant%C3%A9-infantiles/anomalies-chromosomiques-et-g%C3%A9n%C3%A9tiques/trisomie-21-syndrome-de-down">
              {t('resource3')}
            </a>
          </Li>
          <Li>
            <a href="https://www.merckmanuals.com/fr-ca/accueil/probl%C3%A8mes-de-sant%C3%A9-infantiles/anomalies-chromosomiques-et-g%C3%A9n%C3%A9tiques/trisomie-18?query=trisomie%2018">
              {t('resource4')}
            </a>
          </Li>
          <Li>
            <a href="https://www.merckmanuals.com/fr-ca/accueil/probl%C3%A8mes-de-sant%C3%A9-infantiles/anomalies-chromosomiques-et-g%C3%A9n%C3%A9tiques/trisomie-13?query=trisomie%2013">
              {t('resource5')}
            </a>
          </Li>
        </ul>
      </Section>
      <Section title={t("FAQ")}>
        <Box marginTop="15px">
          <Typography>
            <strong>{t("Q: What is a risk factor?")}</strong>
            <br/><br/>
            {t("A: According to the World Health Organization (WHO)")}
            <br/><br/>
            <strong>{t("Q: What are the other risk factors for trisomy 21, 18, or 13?")}</strong>
            <br/><br/>
            {t("A: Genetics, diet, lifestyle, and environmental factors can increase the likelihood of having a child with a trisomy.")}
            <br/><br/>
            <strong>{t("Q: Why wasn't I offered the same screening as my friend/colleague/sister/acquaintance?")}</strong>
            <br/><br/>
            {t("A: The offer of screening varies depending on your risk profile.")}
            <br/><br/>
            <strong>{t("Q: Why don't I have a result for my non-invasive prenatal testing (NIPT)?")}</strong>
            <br/><br/>
            {t("A: It is possible for a non-invasive prenatal testing result to be uninterpretable.")}
            <br/><br/>
            <strong>{t("Q: What happens when I do not have a result for my non-invasive prenatal testing (NIPT)?")}</strong>
            <br/><br/>
            {t("A: Given the short time frame for decision-making, amniocentesis will be offered if the non-invasive prenatal genomic test fails.")}
            <br/><br/>
            <strong>{t("Q: What should I do after a positive diagnostic test?")}</strong>
            <br/><br/>
            {t("A: Following a positive diagnostic test, you must make a decision about the outcome of the pregnancy. You must decide whether to continue with or terminate the pregnancy.")}
            <br/><br/>
            <strong>{t("Q: If I decide to proceed outside of the Québec Prenatal Screening Program, will the costs incurred for the tests be covered by my insurance?")} </strong>
            <br/><br/>
            {t("A: Depending on your insurer, some or all of the costs incurred for prenatal screening tests in private clinics may be covered.")}
            <br/><br/>
            <strong>{t("Q: Do screening tests detect other health conditions?")}</strong>
            <br/><br/>
            {t("A: All screening tests can detect many other conditions besides trisomy 21, 18, and 13.")}
            <br/><br/>
            <strong>{t("Q: Will I be given information about other conditions detected?")}</strong>
            <br/><br/>
            {t("A: In the public health care system, in addition to your probability of having a child with trisomy 21, 18, or 13, your results report will indicate any other \"abnormal\" results.")}
            <br/><br/>
          </Typography>
        </Box>
      </Section>
      <Section title={t("Test comparison: what happens after each test?")}>
        <Box display="flex"
              flexDirection="row"
              alignItems="center"
              marginTop="20px">
            <FormControl component="fieldset">
              <RadioGroup row aria-label="position" name="position" defaultValue="top" onChange={handleChange}>
                <FormControlLabel
                  checked={selected === "tbi"}
                  value="tbi"
                  control={<Radio color="primary" />}
                  label={t("Integrated biochemical test")}
                />
                <FormControlLabel
                  checked={selected === "tbitpgni"}
                  value="tbitpgni"
                  control={<Radio color="primary" />}
                  label={`${t("NIPT")} (${t("following an Integrated biochemical test with high probability")})`}
                />
                <FormControlLabel
                  checked={selected === "tbicn"}
                  value="tbicn"
                  control={<Radio color="primary" />}
                  label={`${t("Integrated biochemical test")} + ${t("Nuchal translucency ultrasound")}`}
                />
              </RadioGroup>   
            </FormControl>
            <Box marginLeft="auto">
              <FormControlLabel
                control={
                  <Switch
                    checked={inImage}
                    onChange={handleToggleInImage}
                    name="inImage"
                    color="primary"
                  />
                }
                label={t("In pictures")}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={inPercentage}
                    onChange={handlePercentageToggle}
                    name="percentage"
                    color="primary"
                    disabled={inImage}
                  />
                }
              label={t("Percentage")}
            />
          </Box>
        </Box>
        <Box marginBottom="10px">
          {inImage ? <ImageSection selected={selected}/> : <PieChartSection selected={selected} inPercentage={inPercentage}/>}
          {selected === "tbi" ? <TbiInfo percent={inPercentage} /> : null}
          {selected === "tbicn" ? <TbiCnInfo percent={inPercentage} /> : null}
          {selected === "tbitpgni" ? <TgpniInfo percent={inPercentage} /> : null}
        </Box>
        <AccentFrame>
          <Typography component="span">
            <Trans i18nKey="screeningStudies">
              Studies are limited as regards the performance of nuchal translucency measurement when conducted alone 
              and of the <Definition word="tgpni">non-invasive prenatal testing</Definition> when conducted as an initial screening test. 
              Therefore, we encourage you to discuss this issue with your health care providers."
            </Trans>
          </Typography>
        </AccentFrame>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
